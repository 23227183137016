<template flex>
  <div class="login d-flex justify-center">
    <v-main class="d-flex">
        <v-layout fluid flex flex-wrap justify-center>
          <v-flex d-flex xs12 sm5 align-center elevation-0>
            <v-card flat class="pa-16" width="100%">
                <div class="d-flex mb-16 pb-3"><a href="/login" class="text-decoration-none black--text"><v-icon small left> arrow_back </v-icon> Back</a></div>
                <v-layout class="flex-column">
                    <h4 class="display-1 font-weight-medium pb-2">Verify E-mail</h4>
                </v-layout>
                <v-card-text class="py-4 px-0">
                    <p v-if="true">
                        <strong>{{ msg }}</strong>
                    </p>
                    <v-form v-model="valid" ref="form" :action="action" method="POST" role="form">
                        <input type="hidden" name="csrf_token" :value="csrf_token">
                        <v-text-field
                            label="Email Address"
                            placeholder="Enter your e-mail address"
                            v-model="email"
                            name="email"
                            required
                            outlined
                            dense
                            :rules="emailRules"
                        ></v-text-field>

                        <v-dialog
                            v-model="dialog"
                            persistent
                            max-width="600px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                    <v-btn type='submit'
                                      block
                                      depressed large
                                      v-bind="attrs"
                                      v-on="off"
                                      class="primary white--text mb-7"
                                      :disabled="!valid"
                                    >Submit</v-btn>
                            </template>
                            <v-card class="pa-16">
                                <v-card-text>
                                    <v-container class="text-center">
                                        <v-row justify="center" class="mb-6">
                                            <v-img
                                            max-height="173"
                                            max-width="173"
                                            src="../assets/images/success-check.svg"
                                            ></v-img>
                                        </v-row>
                                        <h2 class="pb-4 font-weight-medium black--text">Please Check Your Email </h2>
                                        <p>To set a new password please check your email</p>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="justify-center mt-7">
                                    <v-btn
                                        width="164"
                                        color="success"
                                        depressed
                                        large
                                        @click="dialog = false"
                                    >
                                        Close
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <a href="/login" class="text-center mb-4">Login Instead</a>
                </v-card-text>
            </v-card>
          </v-flex>
          <v-flex d-flex xs12 sm7 align-center elevation-0 blue accent-4>
            <v-card flat class="blue accent-4 pa-16">
              <div class="pt-4 white--text">
                <h2 class="display-1 font-weight-medium pb-6">The Kochava Marketers Operating System™</h2>
                <p>The Marketers Operating System (m/OS) seamlessly integrates omni-channel marketing solutions for advertisers and publishers in one operational platform.</p>
              </div>
              <v-card-text class="pt-4 px-0 white--text">
                <v-img src="../assets/images/login-img.svg"></v-img>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
    </v-main>
  </div>
</template>

<script>
import Vue from 'vue';
import { FlowService } from '@services/flow.service';

export default Vue.extend({
  name: 'Recovery',
  components: {
  },
    data () {
    return {
      email: "",
      valid:null,
      flow:null,
      action:null,
      csrf_token : null,
      showmsg: false,
      dialog:false,
      msg:"No msg",
      inputEmail: 'Input your E-Mail Id here',
      off: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  methods: {

    buildAction: function (){
        this.msg = "";
        this.flow = this.$route.query.flow;
    },

    goToLogin : function () {
      return this.$router.push('login');
    },

    getRecoveryFlowStatus: async function (){
        await FlowService.flow({ flowId: this.flow, type:'verify' })
        .then((res) => {
          //console.log("FlowServiceRes--->",res);
          this.action = res.data.action;
          this.csrf_token = res.data.token;
          if(res.data.state == 'choose_method'){
            this.showmsg = true;
            this.msg='Please enter your registered E-mail Id'
          }
          if(res.data.state == 'sent_email'){
            this.showmsg = true;
            this.msg=res.data.message[0].text;
          }
        })
        .catch((err) => console.log(err));
    }
  },

  beforeMount(){
    this.buildAction();
    this.getRecoveryFlowStatus()
 },
});
</script>
